const CACHE_NAME = 'v1';
const DB_NAME = 'URL_DB';
const STORE_NAME = 'URLS';
let db: IDBDatabase;

/**
 * Opens an IndexedDB or creates a new one if needed.
 *
 * @param callback callback function to run on success.
 */
function openDB(callback?: Function) {
  const openRequest = self.indexedDB.open(DB_NAME, 3);

  openRequest.onerror = function (event: any) {
    console.error('Failed to open IndexedDB: ' + event.target.errorCode);
  };

  openRequest.onupgradeneeded = function (event: any) {
    db = event.target.result;
    if (!db.objectStoreNames.contains(STORE_NAME)) {
      const urlStore = db.createObjectStore(STORE_NAME, {
        autoIncrement: true,
      });
      urlStore.createIndex('url', 'url', { unique: true });
    }
  };

  openRequest.onsuccess = function (event: any) {
    db = event.target.result;
    if (callback) {
      callback();
    }
  };
}

/**
 * Adds to store.
 *
 * @param value the value to add
 */
function addToStore(value: string) {
  if (typeof window !== 'undefined') {
    const transaction = db.transaction(STORE_NAME, 'readwrite');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.put({ url: value });

    request.onsuccess = function () {};

    request.onerror = function () {
      console.error('Error did not save to store: ', request.error);
    };

    transaction.onerror = function (event: any) {
      console.error('Transaction failed when saving to store: ', event);
    };
  }
}

/**
 * Fetches from store.
 * @param key the key of the value to fetch.
 * @returns the first value matching the key; otherwise undefined.
 */
async function getFromStore(key: string) {
  if (typeof window !== 'undefined') {
    return new Promise(async function (resolve, reject) {
      const transaction = db.transaction(STORE_NAME, 'readwrite');
      const store = transaction.objectStore(STORE_NAME);
      const index = store.index('url');
      const request = index.get(key);
      request.onerror = function () {
        reject(false);
      };
      transaction.onerror = function (event: any) {
        reject(false);
      };
      return (request.onsuccess = (event: any) => {
        return event.target.result ? resolve(true) : resolve(false);
      });
    });
  }
}

/**
 * Adds response to API cache.
 *
 * @param request the request.
 * @param response the response.
 */
const putInCache = async (request: Request, response: Response) => {
  const cache = await caches.open(CACHE_NAME);
  console.debug('updating cache for ', request.url);
  await cache.put(request, response);
};

/**
 * Fetch from cache or API and update cache with response.
 *
 * @param event event with request.
 * @returns the response from cache or API.
 */
const staleWhileRevalidate = async (event: any) => {
  const request = event.request as Request;
  const cache = await caches.open(CACHE_NAME);
  const responseFromCache = await cache.match(request);
  const responseFromNetwork = fetch(request);
  event.waitUntil(
    (async function () {
      const networkResponse = await responseFromNetwork;
      if (networkResponse.ok) {
        await putInCache(request, networkResponse.clone());
      }
    })()
  );
  responseFromCache
    ? console.debug('\x1B[31mresponse for ', event.request.url, ' from cache')
    : console.debug('\x1B[31mresponse for ', event.request.url, ' from API');
  return responseFromCache || responseFromNetwork;
};

/**** LISTENERS ****/

self.addEventListener('install', (event: any) => {
  event.waitUntil(openDB());
});

self.addEventListener('message', async (event: any) => {
  if (event.data && event.data.type === 'ADD_TO_CACHE') {
    openDB();
    const url = event.data.url;
    const exists = await getFromStore(url);
    if (!exists) {
      addToStore(url);
      console.debug('\x1B[31madding url: ', url, ' to cache');
    }
  }
});

self.addEventListener('fetch', async (event: any) => {
  var url = new URL(event.request.url);
  openDB();
  const promise = getFromStore(url.pathname + url.search);
  event.respondWith(
    promise.then((exists) => {
      if (exists) {
        return staleWhileRevalidate(event);
      } else {
        return fetch(event.request).then();
      }
    })
  );
});
